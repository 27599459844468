html {
    height: 100%;
    background-color: #222;
}

body {
    margin: 0;
    padding: 0;
    border: none;
    font-family: Consolas, Menlo, Courier, monospace;
    color: #ccc;
    height: 100%;
    position: relative;
    font-size: 24px;
}

#root {
    margin: 0;
    padding: 0;
    border: none;
    font-family: Consolas, Menlo, Courier, monospace;
    color: #ccc;
    height: 100%;
    position: relative;
}

div {
    box-sizing: border-box;
    border-width: 0;
}

a {
    color: #88f;
}

input, button, select {
    background-color: #222;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 24px;
    padding: 5px;
}

select {
    padding-left: 0;
}

select option {
    font-size: 20px;
}

input:hover, button:hover {
    background-color: #333;
}

button:disabled, button:disabled:hover {
    color: #888;
    border-color: #888;
    background-color: #222;
}
