.container {
    width: 100%;
    height: 100%;
}

@media (min-width: 401px) {
    .cell {
        width: 400px;
    }
}

@media (max-width: 400px) {
    .cell {
        width: 100%;
    }
}
